import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import MainBg from "../components/mainBg";
import Callout from "../components/callout";
import DidYouKnow from "../components/didYouKnow";
import NewsletterSignup from "../components/newsletterSignup";
import CallToAction from "../components/callToAction";

class ProfessionalLiabilityInsurance extends React.Component {
  render() {
    
    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="Professional Liability Insurance - Baton Rouge | Lewis Mohr"
          meta={[{ name: "description", content: "If you or your company in Louisiana make a living off your expertise, Professional Liability Insurance is imperative for your company. Contact us for an insurance quote!" }]}  
        />
        <MainBg mainBg={this.props.data.mainBg} imgStyle={{objectPosition: 'bottom'}} alt="Doctor meeting with patient" />
        <Callout
          title="Professional Liability Insurance"
          content="If you or your company in Louisiana makes a living off your expertise, Professional Liability Insurance (Errors & Omissions Insurance) is imperative for your company. This includes professions such as engineers, lawyers, doctors, IT consultants, accountants, architects and more. If your work fails to meet standards set by your state, industry or your contract with a customer, you open yourself to substantial risk which this insurance helps to compensate for."
        />
        <div className="site-body page-content">
          <h2>What Professional Liability Insurance Usually Covers</h2>
          <p>
          <ul>
          <li><strong>Work Mistakes.</strong> When you get sued for making an error that costs a client money.</li>
          <li><strong>Undelivered Services.</strong> If you promise a client a particular result from finished work as part of a contract and it isn’t fulfilled this insurance will help pay lawsuit expenses.</li>
          <li><strong>Negligent Services.</strong> If your work doesn't meet the standard set, it can help pay legal expenses from being sued.</li>
        </ul>
          </p>
          <DidYouKnow
            fact="The median cost of a contract dispute is $91,000."
            source="Court Statistics Project"
          />
          <h2>What Professional Liability Insurance Usually Does Not Cover</h2>
          <p>
          <ul>
          <li>Intentional wrongdoing and illegal acts.</li>
          <li>General Liabilities.</li>
          <li>Employee Injuries.</li>
          <li>Employment Disputes.</li>
          <li>False Advertising.</li>
        </ul>
          </p>
        </div>
        <CallToAction bg={this.props.data.callToActionBg} type="a Professional Liability Insurance" />
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default ProfessionalLiabilityInsurance;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    mainBg: file(relativePath: { eq: "professional-liability-insurance-bg.jpg" }) {
      ...mainBg
    }
    callToActionBg: file(relativePath: { eq: "bg-cta-12.jpg" }) {
      ...mainBg
    }
  }
`;
